import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Card, CardHeader, Input } from "reactstrap";

import { withTranslation } from "react-i18next";
import CustomAddButton from "../../components/common/CustomAddButton";
import CustomSearchInput from "../../components/common/CustomSearchInput";
import DeleteModal from "../../components/common/DeleteModal";
import Loader from "../../components/common/Loader";
import NoRecords from "../../components/common/NoRecords";
import { ADMIN, COMUNICATIONS, DEFAULT_ROW_PER_PAGE } from "../../helpers/constants";
import {
  deleteCustomer,
  exportCustomers,
  getCustomerList,
  getShippingOptions,
  mergeCustomer,
  updateBulkUser,
} from "../../store/customers/actions";

import { useHistory } from "react-router-dom";
import Select, { components } from "react-select";
import { toast } from "react-toastify";
import CustomButton from "../../components/common/CustomButton";
import CustomColumnHeader from "../../components/common/CustomColumnHeader";
import CustomDropdownButton from "../../components/common/CustomDropdownButton";
import CustomMultiSelect from "../../components/common/CustomMultiSelect";
import CustomTooltipWrapper from "../../components/common/CustomTooltipWrapper";
import UpdateBulkUserModal from "../../components/pages/customers/UpdateBulkUserModal";
import API from "../../helpers/api/api_interceptor";
import {
  ADDRESS_OPTION,
  SELECT_ALL_OPTION,
  SELECT_NO_OPTION,
  YES_NO_OPTIONS,
} from "../../helpers/constants/selectOptions";
import {
  checkNoSelected,
  checkPermissionByRoles,
  checkSelectAllSelected,
  exportCustomersToExcel,
  generateCustomerExportData,
  getColumnDetail,
  getConcatedStringFromArray,
  getCustomerAddress,
  getCustomerVat,
  getDateInDDMMYYYYFormat,
  getIdFromArray,
  getLabelValueArray,
  getOnlyCustomerName,
  getValuesFromArray,
  openFileURL,
} from "../../helpers/utils";
import { getCountriesOptions } from "../../store/common/actions";
import { SET_CUSTOMER_DETAILS, SET_PERSON_DETAILS } from "../../store/customers/actionType";
import MergeCustomerModal from "../../components/common/MergeCustomerModal";
import CustomPagination from "../../components/common/CustomPagination";

export const INITIAL_GET_CUSTOMER_PAYLOAD = {
  search: "",
  page: 1,
  limit: DEFAULT_ROW_PER_PAGE,
  sort: "sort_id",
  filter: {},
};

const Customers = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    data: customersData,
    total: totalCustomers,
    all_ids: allCustomerIds,
  } = useSelector((state) => state?.Customers);

  // handle row selection
  const handleRowSelection = (rowId) => {
    const isSelected = Boolean(selectedRows.find((item) => item === rowId));

    if (isSelected) {
      setSelectedRows((prevState) => prevState.filter((item) => item !== rowId));
      setIsAllSelected(false);
    } else {
      setSelectedRows((prevState) => [...prevState, rowId]);
    }
  };

  // handle clear filters
  const handleClearFilters = () => {
    setCustomersPayload(INITIAL_GET_CUSTOMER_PAYLOAD);
    setSelectedAddress("");
    setSelectedTags([]);
    setSelectedPostcards([]);
    setSelectedCatalogues([]);
    setSelectedCataloguesSubscription([]);
    setSelectedNewsletterSubscriptionOptions([]);
    setSelectedReceiveTextOptions([]);
    setClearSearch(true);
    setSelectedRows([]);
    setIsAllSelected(false);
  };

  const countriesOptions = getLabelValueArray(
    useSelector((state) => state?.common?.countriesOptions?.data) || [],
    "id",
    "code"
  );
  const shippingOptionList = getLabelValueArray(
    useSelector((state) => state?.Customers?.shippingOptions?.results) || [],
    "id",
    getColumnDetail("name_en", "name_pt")
  );
  const tagOptionList = getLabelValueArray(
    useSelector((state) => state?.Customers?.tagOptions?.results) || [],
    "id",
    getColumnDetail("name_en", "name_pt")
  );

  const [customersPayload, setCustomersPayload] = useState(INITIAL_GET_CUSTOMER_PAYLOAD);

  const [selectedItem, setSelectedItem] = useState(null);
  const [tableLoader, setTableLoader] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [sortingOrder, setSortingOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [isDeleteActionAllowed, setIsDeleteActionAllowed] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedPostcards, setSelectedPostcards] = useState([]);
  const [selectedCatalogues, setSelectedCatalogues] = useState([]);
  const [selectedCataloguesSubscription, setSelectedCataloguesSubscription] = useState([]);

  const [exportBtnLoader, setExportBtnLoader] = useState(false);
  const [isUpdatingUser, setIsUpdatingUser] = useState(false);
  const [isBulkUpdateUserModalOpen, setIsBulkUpdateUserModalOpen] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedReceiveTextOptions, setSelectedReceiveTextOptions] = useState([]);
  const [selectedNewsletterSubscriptionOptions, setSelectedNewsletterSubscriptionOptions] = useState([]);
  const [clearSearch, setClearSearch] = useState();

  const [isMergeCustomerModalOpen, setIsMergeCustomerModalOpen] = useState(false);
  const [mergeCustomerLoader, setMergeCustomerLoader] = useState(false);

  // get countries list
  useEffect(() => {
    dispatch(getCountriesOptions({ sort: "name" }));
    dispatch(
      getShippingOptions({
        sort: getColumnDetail("name_en", "name_pt"),
        filter: { type: "shipping" },
      })
    );
    dispatch(
      getShippingOptions({
        sort: getColumnDetail("name_en", "name_pt"),
        filter: { type: "tag" },
      })
    );
  }, [dispatch]);

  //   navigate on customer from
  const navigateOnCustomerForm = () => {
    dispatch({
      type: SET_CUSTOMER_DETAILS,
      payload: {},
    });
    dispatch({
      type: SET_PERSON_DETAILS,
      payload: {},
    });
    history.push("/customers/new");
  };

  //   navigate on edit customer from
  const navigateOnEditCustomerForm = (id) => {
    dispatch({
      type: SET_CUSTOMER_DETAILS,
      payload: {},
    });
    history.push(`/customers/edit/${id}`);
  };

  //   navigate on edit customer from
  const navigateOnEditPersonaForm = (customerID, personaId) => {
    history.push(`/customers/${customerID}/edit-person/${personaId}`);
  };

  // delete action handlers
  const handleDeleteModalOpen = (itemData) => {
    setSelectedItem(itemData);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setIsDeleteActionAllowed(false);
    setSelectedItem(null);
    setIsDeleteModalOpen(false);
  };
  const handleDeleteCustomer = () => {
    if (selectedItem && selectedItem?.id) {
      setDeleteLoader(true);
      dispatch(
        deleteCustomer({
          id: selectedItem?.id,
          payload: { is_admin: isDeleteActionAllowed },
        })
      )
        .then((res) => {
          if (res.data.status === 200) {
            if (!res?.data?.data?.action_allowed) {
              handleDeleteModalClose();
            }
            setIsDeleteActionAllowed(res?.data?.data?.action_allowed);
            toast.warn(res?.data?.detail);
          } else if (res.data.status === 204) {
            toast.success(res?.data?.detail);
            handleGetCustomer(customersPayload);
            handleDeleteModalClose();
          }
          setDeleteLoader(false);
        })
        .catch((error) => {
          setDeleteLoader(false);
          handleDeleteModalClose();
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setCustomersPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setCustomersPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle product or service type filter
  const handleDropdownFilter = (values, filterKey, setOptionTypes) => {
    setCustomersPayload((prevState) => {
      const filter = { ...prevState.filter };
      if (checkSelectAllSelected(values)) {
        setOptionTypes(SELECT_ALL_OPTION);
        delete filter[filterKey];
        delete filter[`${filterKey}__in`];
        filter[`${filterKey}__isnull`] = false;
      } else {
        delete filter[`${filterKey}__isnull`];
        if (checkNoSelected(values)) {
          filter[`${filterKey}__isnull`] = true;
        }
        const valuesWithoutNull = values.filter(({ value }) => value !== "-1");
        if (valuesWithoutNull.length) {
          filter[`${filterKey}__in`] = getValuesFromArray(valuesWithoutNull);
        } else {
          delete filter[filterKey];
          delete filter[`${filterKey}__in`];
        }
      }

      return { ...prevState, filter, page: 1 };
    });
  };

  // handle sorting functionality
  useEffect(() => {
    if (orderBy !== "" && sortingOrder !== "")
      setCustomersPayload((prevState) => ({
        ...prevState,
        sort: sortingOrder === "asc" ? orderBy : sortingOrder === "desc" ? `${"-" + orderBy}` : "",
      }));
  }, [orderBy, sortingOrder]);

  // handle get products and services
  const handleGetCustomer = useCallback(
    (customersPayload) => {
      setTableLoader(true);
      dispatch(getCustomerList(customersPayload))
        .then((res) => {
          setTableLoader(false);
          setExportBtnLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetCustomer(customersPayload);
  }, [customersPayload, handleGetCustomer]);

  // for address filter

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input type="checkbox" checked={props.isSelected} onChange={() => null} />
          <label className="ms-2">{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const handleAddressFilter = (selectedItem, filterKey) => {
    if (selectedItem && selectedItem.value === "*") {
      setSelectedAddress(SELECT_ALL_OPTION);
      return setCustomersPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.exclude;
        delete tempPayload.filter[filterKey];
        return { ...tempPayload, page: 1 };
      });
    } else if (selectedItem && selectedItem.value === "portugal") {
      let tempPayload = customersPayload;
      delete tempPayload.exclude;

      return setCustomersPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.exclude;
        return {
          ...tempPayload,
          filter: {
            ...prevState.filter,
            [filterKey]: getIdFromArray(countriesOptions, "PT"),
          },
          page: 1,
        };
      });
    } else if (selectedItem && selectedItem.value === "internationals") {
      return setCustomersPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter[filterKey];
        return {
          ...tempPayload,
          exclude: {
            [filterKey]: getIdFromArray(countriesOptions, "PT"),
          },
          page: 1,
        };
      });
    } else {
      setCustomersPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter[filterKey];
        delete tempPayload.exclude;
        return { ...tempPayload, page: 1 };
      });
    }
  };

  const handleExportPDF = () => {
    const payload = {
      ids: selectedRows,
      exclude: customersPayload?.exclude,
    };
    setExportBtnLoader(true);
    dispatch(exportCustomers(payload)).then((res) => {
      if (res) {
        openFileURL(res, "download");
      }
      setExportBtnLoader(false);
    });
  };
  const handleExportExcel = () => {
    setExportBtnLoader(true);
    let filter = {};
    if (selectedRows?.length) {
      filter.id__in = selectedRows;
    }
    API.get(`/customers/`, {
      params: {
        filter,
        sort: customersPayload?.sort,
        exclude: customersPayload?.exclude,
      },
    })
      .then((res) => {
        if (res && res.status && res.status === 200) {
          exportCustomersToExcel(
            generateCustomerExportData(res?.data?.data?.results),
            `Customer List-${getDateInDDMMYYYYFormat(new Date())}`,
            [15, 15, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 25, 25, 25]
          );
        }
        setExportBtnLoader(false);
      })
      .catch((error) => {
        setExportBtnLoader(false);
      });
  };

  const handleBulkUserModalOpen = () => {
    setIsBulkUpdateUserModalOpen(true);
  };

  const handleBulkUserModalClose = () => {
    setIsUpdatingUser(false);
    setIsBulkUpdateUserModalOpen(false);
  };

  const handleUpdateBulkUser = (values, formikValues) => {
    setIsUpdatingUser(true);
    dispatch(
      updateBulkUser({
        isAllSelected: false,
        ids: selectedRows,
        is_active: values?.is_active,
      })
    )
      .then((res) => {
        if (res) {
          handleBulkUserModalClose();
          handleGetCustomer(customersPayload);
        } else {
          setIsUpdatingUser(false);
        }
      })
      .catch((error) => {
        setIsUpdatingUser(false);
        handleBulkUserModalClose();
        console.log(error);
      });
  };

  const handleSelectAll = (event) => {
    const hasFilterOrSearchApplied =
      !!Object.keys(customersPayload?.filter)?.length || !!customersPayload?.search?.length;
    const isAllSelected = !!event.target.checked;

    if (!isAllSelected) {
      if (hasFilterOrSearchApplied) {
        setSelectedRows((prevState) => [...prevState?.filter((item) => !allCustomerIds?.includes(item))]);
      } else {
        setSelectedRows([]);
      }
    } else {
      if (hasFilterOrSearchApplied) {
        setSelectedRows((prevState) => [...new Set([...prevState, ...allCustomerIds])]);
      } else {
        setSelectedRows(allCustomerIds);
      }
    }
    setIsAllSelected(isAllSelected);
  };

  const handleMergeCustomerModalOpen = () => {
    setIsMergeCustomerModalOpen(true);
  };

  const handleMergeCustomerModalClose = () => {
    setMergeCustomerLoader(false);
    setIsMergeCustomerModalOpen(false);
  };

  const handleMergeCustomerModalConfirm = (payload) => {
    setMergeCustomerLoader(true);
    dispatch(mergeCustomer(payload))
      .then((res) => {
        if (res) {
          handleMergeCustomerModalClose();
          handleGetCustomer(customersPayload);
        }
      })
      .catch((error) => {
        handleMergeCustomerModalClose();
      });
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4 d-flex flex-row justify-content-center">
          <Input
            disabled={!customersData?.length}
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              handleSelectAll(event);
            }}
          />
        </div>
      ),
      selector: (row) => (
        <Input
          disabled={!customersData?.length}
          type="checkbox"
          checked={Boolean(selectedRows.find((item) => item === row.id))}
          onChange={() => handleRowSelection(row.id)}
        />
      ),
      width: "50px",
      center: true,
    },

    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("customers.customer")} ${props.t("common.no_")}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="customer_no__icontains"
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
            className="column-search-input"
            payload={customersPayload}
            setPayload={setCustomersPayload}
          />
        </div>
      ),
      selector: (row) => row?.customer_no,
      width: "150px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.title")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={getColumnDetail("title_gen__title_en__icontains", "title_gen__title_pt__icontains")}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
            className="column-search-input"
            payload={customersPayload}
            setPayload={setCustomersPayload}
          />
        </div>
      ),
      selector: (row) =>
        row?.title_gen?.id ? getColumnDetail(row?.title_gen?.title_en, row?.title_gen?.title_pt) : "-",
      width: "150px",
      wrap: true,
    },

    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.name")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="search_customer__icontains"
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
            className="column-search-input"
            payload={customersPayload}
            setPayload={setCustomersPayload}
          />
        </div>
      ),
      selector: (row) => getOnlyCustomerName(row),
      //   width: "15%",
      width: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.vat")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="search_vat__icontains"
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
            className="column-search-input"
            payload={customersPayload}
            setPayload={setCustomersPayload}
          />
        </div>
      ),
      selector: (row) => getCustomerVat(row),
      //   width: "10%",
      width: "180px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.contact1")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="contact_1__icontains"
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
            className="column-search-input"
            payload={customersPayload}
            setPayload={setCustomersPayload}
          />
        </div>
      ),
      selector: (row) => row?.contact_1 || "-",
      //   width: "10%",
      width: "180px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.email")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="email__icontains"
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
            className="column-search-input"
            payload={customersPayload}
            setPayload={setCustomersPayload}
          />
        </div>
      ),
      selector: (row) => row?.email,
      //   width: "15%",
      width: "180px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={`${props.t("common.address")}`}
            sortable
            sortableKey="customers_address__address"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <Select
            placeholder={props.t("common.select")}
            className="custom-select"
            isMulti={false}
            components={{ Option }}
            isClearable
            options={[SELECT_ALL_OPTION, ...ADDRESS_OPTION]}
            value={selectedAddress}
            onChange={(selectedItem) => {
              if (selectedItem === null) {
                setSelectedAddress("");
              }
              setSelectedAddress(selectedItem);
              handleAddressFilter(selectedItem, "customers_address__country");
            }}
          />
        </div>
      ),
      selector: (row) =>
        getCustomerAddress(row?.addresses, row?.customer_type === "company" ? "company" : "client") || "-",
      //   width: "15%",
      width: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={`${props.t("common.tags_capital")}`}
            sortable
            sortableKey="tags"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[
              SELECT_ALL_OPTION,
              { ...SELECT_NO_OPTION, label: `${props.t("common.noOption")} ${props.t("common.tags_capital")}` },
              ...tagOptionList,
            ]}
            selectedOptions={selectedTags}
            handleOnSelection={(values) => {
              setSelectedTags(values);
              handleDropdownFilter(values, "tags", setSelectedTags);
            }}
          />
        </div>
      ),
      selector: (row) => getConcatedStringFromArray(row?.tags, getColumnDetail("name_en", "name_pt")),
      //   width: "10%",
      width: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("customers.postCardsShipping")}`}</div>

          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[
              SELECT_ALL_OPTION,
              { ...SELECT_NO_OPTION, label: `${props.t("common.noOption")} ${props.t("common.noPostcardOption")}` },
              ...shippingOptionList,
            ]}
            selectedOptions={selectedPostcards}
            handleOnSelection={(values) => {
              setSelectedPostcards(values);
              handleDropdownFilter(values, "postcards", setSelectedPostcards);
            }}
          />
        </div>
      ),
      selector: (row) => getConcatedStringFromArray(row?.postcards, getColumnDetail("name_en", "name_pt")),
      //   width: "10%",
      width: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${`${props.t("customers.cataloguesShipping")}`}`}</div>

          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[
              SELECT_ALL_OPTION,
              {
                ...SELECT_NO_OPTION,
                label: `${props.t("common.noOption")} ${props.t("common.noCatalogShippingOption")}`,
              },
              ...shippingOptionList,
            ]}
            selectedOptions={selectedCatalogues}
            handleOnSelection={(values) => {
              setSelectedCatalogues(values);
              handleDropdownFilter(values, "catalogue_shippings__catalogues", setSelectedCatalogues);
            }}
          />
        </div>
      ),
      selector: (row) =>
        getConcatedStringFromArray(row?.active_catalogue_shippings, getColumnDetail("name_en", "name_pt")),
      //   width: "10%",
      width: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.cataloguesSubscription")}</div>
          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[
              SELECT_ALL_OPTION,
              {
                ...SELECT_NO_OPTION,
                label: `${props.t("common.noOption")} ${props.t("common.noCatalogOption")}`,
              },
              ...shippingOptionList,
            ]}
            selectedOptions={selectedCataloguesSubscription}
            handleOnSelection={(values) => {
              setSelectedCataloguesSubscription(values);
              handleDropdownFilter(values, "catalogue_subscriptions__catalogues", setSelectedCataloguesSubscription);
            }}
          />
        </div>
      ),
      selector: (row) =>
        getConcatedStringFromArray(row?.active_catalogue_subscriptions, getColumnDetail("name_en", "name_pt")),
      //   width: "10%",
      width: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.newsletterSubscription")}</div>
          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[SELECT_ALL_OPTION, ...YES_NO_OPTIONS]}
            selectedOptions={selectedNewsletterSubscriptionOptions}
            handleOnSelection={(values) => {
              setSelectedNewsletterSubscriptionOptions(values);
              handleDropdownFilter(values, "is_subscribed_to_newsletter", setSelectedNewsletterSubscriptionOptions);
            }}
          />
        </div>
      ),
      selector: (row) => (row?.is_subscribed_to_newsletter ? props.t("common.yes") : props.t("common.no")),
      width: "194px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.receivesTextMessage")}</div>
          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[SELECT_ALL_OPTION, ...YES_NO_OPTIONS]}
            selectedOptions={selectedReceiveTextOptions}
            handleOnSelection={(values) => {
              setSelectedReceiveTextOptions(values);
              handleDropdownFilter(values, "is_received_text", setSelectedReceiveTextOptions);
            }}
          />
        </div>
      ),
      selector: (row) => (row?.is_received_text ? props.t("common.yes") : props.t("common.no")),
      width: "190px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.action")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <button
            type="button"
            className={`btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none ${
              checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "" : "disabled"
            }`}
            onClick={() => {
              handleDeleteModalOpen(row);
            }}
            disabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS])}
            style={{
              outline: "none",
              border: "none",
              opacity: checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "1" : "0.5",
              cursor: checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "pointer" : "not-allowed",
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
            <i
              className={`ri-delete-bin-fill fs-18 ${
                checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "text-danger" : "text-grey"
              }`}
              id={`tooltip-${row?.id}-delete`}
              style={{
                color: checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "inherit" : "grey",
                border: "none",
              }}
            />
          </button>
        </div>
      ),
      //   width: "8%",
      width: "100px",
      center: true,
    },
  ];

  document.title = `Veritas | ${props.t("sidebarMenu.customers")}`;

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header">
          <CustomSearchInput
            className="custom-search-input"
            payload={customersPayload}
            setPayload={setCustomersPayload}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
            disabled={tableLoader}
          />

          <div className="contain-header-right d-flex flex-row gap-2">
            {(isAllSelected && totalCustomers > 0) || selectedRows.length ? (
              <Button
                color="info"
                onClick={() => {
                  setSelectedRows([]);
                  setIsAllSelected(false);
                }}
              >
                {props.t("common.deselect")}
                <Badge color="dark" className="ms-1">
                  {selectedRows.length || totalCustomers}
                </Badge>
              </Button>
            ) : null}

            {/* Highlighted: Clear Filters Button */}
            <Button color="light" onClick={handleClearFilters} disabled={tableLoader}>
              {props.t("common.clearFilters")}
            </Button>

            <CustomAddButton
              color={"success"}
              onClick={navigateOnCustomerForm}
              btnTitle={`${props.t("common.add")} ${props.t("customers.customer")}`}
            />
            <CustomButton
              color={"success"}
              onClick={handleMergeCustomerModalOpen}
              btnTitle={props.t("common.mergeCustomers")}
            />
            <CustomButton
              btnTitle={`${props.t("common.update", {
                module: props.t("common.status"),
              })}`}
              onClick={() => {
                handleBulkUserModalOpen();
              }}
              disabled={!(isAllSelected && totalCustomers > 0) && selectedRows.length === 0}
            />

            <CustomDropdownButton
              disabled={!(isAllSelected && totalCustomers > 0) && selectedRows.length === 0}
              btnTitle={props.t("common.export")}
              isLoading={exportBtnLoader}
              options={[
                {
                  label: props.t("common.pdf", { lang: "" }),
                  onClick: () => {
                    handleExportPDF();
                  },
                },
                {
                  label: props.t("common.excel"),
                  onClick: () => {
                    handleExportExcel();
                  },
                },
              ]}
            />
          </div>
        </div>
        <Card className="container-body">
          {tableLoader ? <Loader /> : null}
          <CardHeader className="table-title">{props.t("sidebarMenu.customers")}</CardHeader>
          <DataTable
            fixedHeader
            className="data-table"
            persistTableHead
            columns={columns}
            data={[...customersData]}
            pagination
            paginationServer
            paginationTotalRows={totalCustomers}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationPerPage={DEFAULT_ROW_PER_PAGE}
            paginationComponent={CustomPagination}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
            pointerOnHover={true}
            onRowClicked={(row) => {
              if (row?.parent_id) {
                navigateOnEditPersonaForm(row?.parent_id, row?.id);
              } else {
                navigateOnEditCustomerForm(row?.id);
              }
            }}
            conditionalRowStyles={[
              {
                when: (row) => row?.parent_id === null,
                style: { backgroundColor: "#dfdbf178" },
              },
            ]}
          />
        </Card>
      </div>

      {/* bulk update user */}
      <UpdateBulkUserModal
        isModalOpen={isBulkUpdateUserModalOpen}
        onCancel={handleBulkUserModalClose}
        onSave={handleUpdateBulkUser}
        loader={isUpdatingUser}
      />

      {/*Merge customer modal*/}
      <MergeCustomerModal
        isModalOpen={isMergeCustomerModalOpen}
        onCancel={handleMergeCustomerModalClose}
        onConfirm={handleMergeCustomerModalConfirm}
        loader={mergeCustomerLoader}
        title={props.t("common.mergeCustomers")}
      />

      {/* delete modal */}
      <DeleteModal
        title={`${props.t("common.delete")} ${props.t("customers.customer")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("customers.customer"),
        })}
        actionBtnTitle={isDeleteActionAllowed ? `${props.t("common.confirm")}` : ` ${props.t("common.delete")}`}
        loader={deleteLoader}
        isModalOpen={isDeleteModalOpen}
        onConfirmBtnHandler={handleDeleteCustomer}
        onCloseBtnHandler={handleDeleteModalClose}
      />
    </>
  );
};

export default withTranslation()(Customers);
