import { Pagination } from "react-bootstrap";
import PaginationSelect from "./PaginationSelect";
import { useState, useCallback } from "react";

const CustomPagination = (props) => {
  const page = +props.currentPage;
  const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage);
  const totalPages = Math.ceil(+props.rowCount / +rowsPerPage);
  const startIndex = (page - 1) * rowsPerPage + 1;
  const endIndex = page * rowsPerPage > +props.rowCount ? +props.rowCount : page * rowsPerPage;

  const handlePageClick = (eventKey) => {
    const selectedPage = eventKey;
    props.onChangePage(selectedPage);
  };

  const handleRowsPerPage = useCallback(
    (e) => {
      setRowsPerPage(Number(e.target.value));
      props.onChangeRowsPerPage(Number(e.target.value));
    },
    [page, props.onChangeRowsPerPage]
  );

  const renderPagination = () => {
    if (totalPages === 1) {
      return (
        <>
          <Pagination.Prev onClick={() => handlePageClick(page - 1)} disabled={page === 1} />
          <Pagination.Item key={1} active={true}>
            1
          </Pagination.Item>
          <Pagination.Next onClick={() => handlePageClick(page + 1)} disabled={page === totalPages - 1} />
        </>
      );
    }

    const pagesToShow = 5;
    const startPage = Math.max(2, Math.min(totalPages - pagesToShow + 1, page - Math.floor(pagesToShow / 2)));
    const endPage = Math.min(totalPages - 1, startPage + pagesToShow - 1);

    return (
      <>
        <Pagination.Prev onClick={() => handlePageClick(page - 1)} disabled={page === 1} />
        <Pagination.Item key={1} active={1 === page} onClick={() => handlePageClick(1)}>
          1
        </Pagination.Item>
        {startPage > 2 && (
          <Pagination.Item key="ellipsis-start" onClick={() => handlePageClick(startPage - 1)}>
            ...
          </Pagination.Item>
        )}
        {Array.from({ length: endPage - startPage + 1 }, (_, i) => (
          <Pagination.Item
            key={i + startPage}
            active={i + startPage === page}
            onClick={() => handlePageClick(i + startPage)}
          >
            {i + startPage}
          </Pagination.Item>
        ))}
        {endPage < totalPages - 1 && (
          <Pagination.Item key="ellipsis-end" onClick={() => handlePageClick(endPage + 1)}>
            ...
          </Pagination.Item>
        )}
        <Pagination.Item key={totalPages} active={totalPages === page} onClick={() => handlePageClick(totalPages)}>
          {totalPages}
        </Pagination.Item>
        <Pagination.Next onClick={() => handlePageClick(page + 1)} disabled={page === totalPages - 1} />
      </>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "baseline",
        fontSize: "13px",
        color: "rgba(0, 0, 0, 0.54)",
        marginTop: "6px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", marginRight: "8px", gap: "12px" }}>
        <div>{props.paginationComponentOptions.rowsPerPageText}</div>
        <div>
          <PaginationSelect onChange={handleRowsPerPage} defaultValue={rowsPerPage}>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
            <option value={25}>25</option>
            <option value={30}>30</option>
            <option value={1000}>1000</option>
          </PaginationSelect>
        </div>
        <div>
          {startIndex} - {endIndex} of {props.rowCount}
        </div>
      </div>

      <Pagination style={{ color: "rgba(0, 0, 0, 0.54)" }}>{renderPagination()}</Pagination>
    </div>
  );
};

export default CustomPagination;
