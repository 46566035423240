import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Card, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { INITIAL_GET_CUSTOMER_PAYLOAD } from "../../../pages/customers";
import { getCustomerList } from "../../../store/customers/actions";
import CustomSearchInput from "../../common/CustomSearchInput";
import {
  getColumnDetail,
  getCustomerAddress,
  getIdFromArray,
  getLabelValueArray,
  getOnlyCustomerName,
} from "../../../helpers/utils";
import { ADDRESS_OPTION, SELECT_ALL_OPTION } from "../../../helpers/constants/selectOptions";
import CustomColumnHeader from "../../common/CustomColumnHeader";
import Select, { components } from "react-select";
import Loader from "../../common/Loader";
import DataTable from "react-data-table-component";
import NoRecords from "../../common/NoRecords";
import CustomButton from "../../common/CustomButton";

const ChooseSellersModal = ({ isModalOpen, onSend, onCancel, loader, auction_id, notificationType, t }) => {
  const { data: sellers, total: totalSellers, all_ids: allCustomerIds } = useSelector((state) => state?.Customers);
  const dispatch = useDispatch();

  const [customersPayload, setCustomersPayload] = useState({
    ...INITIAL_GET_CUSTOMER_PAYLOAD,
    filter: {
      contracts__contract_items__auction_lots__auction_id: auction_id,
      contracts__is_delete: false,
      contracts__contract_items__is_delete: false,
      contracts__contract_items__auction_lots__is_delete: false,
    },
  });
  const [tableLoader, setTableLoader] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSearch, setClearSearch] = useState();
  const [selectedAddress, setSelectedAddress] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");

  // handle page change
  const handlePageChange = (value) => {
    setCustomersPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setCustomersPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  const handleGetCustomer = useCallback(
    (customersPayload) => {
      setTableLoader(true);
      dispatch(getCustomerList(customersPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  // to get the list of the documents
  useEffect(() => {
    if (isModalOpen) {
      setTableLoader(true);
      handleGetCustomer(customersPayload);
    }
  }, [isModalOpen, customersPayload, handleGetCustomer]);

  // to reset the form
  useEffect(() => {
    if (!isModalOpen && auction_id) {
      setCustomersPayload({
        ...INITIAL_GET_CUSTOMER_PAYLOAD,
        filter: {
          contracts__contract_items__auction_lots__auction_id: auction_id,
          contracts__is_delete: false,
          contracts__contract_items__is_delete: false,
          contracts__contract_items__auction_lots__is_delete: false,
          [`contracts__contract_items__auction_lots__is_${notificationType}_auction_notification_sent`]: false,
        },
      });
      setIsAllSelected(false);
      setSelectedRows([]);
    }
  }, [isModalOpen, auction_id, notificationType]);

  const handleSelectRow = (row) => {
    if (!isAllSelected) {
      if (selectedRows?.find((item) => item === row?.id)) {
        setSelectedRows((prevState) => [...prevState?.filter((item) => item !== row?.id)]);
      } else {
        setSelectedRows((prevState) => [...prevState, row?.id]);
      }
    }
  };

  const countriesOptions = getLabelValueArray(
    useSelector((state) => state?.common?.countriesOptions?.data) || [],
    "id",
    "code"
  );

  const handleAddressFilter = (selectedItem, filterKey) => {
    if (selectedItem && selectedItem.value === "*") {
      setSelectedAddress(SELECT_ALL_OPTION);
      return setCustomersPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.exclude;
        delete tempPayload.filter[filterKey];
        return { ...tempPayload, page: 1 };
      });
    } else if (selectedItem && selectedItem.value === "portugal") {
      let tempPayload = customersPayload;
      delete tempPayload.exclude;

      return setCustomersPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.exclude;
        return {
          ...tempPayload,
          filter: {
            ...prevState.filter,
            [filterKey]: getIdFromArray(countriesOptions, "PT"),
          },
          page: 1,
        };
      });
    } else if (selectedItem && selectedItem.value === "internationals") {
      return setCustomersPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter[filterKey];
        return {
          ...tempPayload,
          exclude: {
            [filterKey]: getIdFromArray(countriesOptions, "PT"),
          },
          page: 1,
        };
      });
    } else {
      setCustomersPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter[filterKey];
        delete tempPayload.exclude;
        return { ...tempPayload, page: 1 };
      });
    }
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input type="checkbox" checked={props.isSelected} onChange={() => null} />
          <label className="ms-2">{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const columns = [
    {
      name: (
        <Label
          onClick={(e) => e.stopPropagation()}
          className="py-4 d-flex flex-row justify-content-center table-check-box"
        >
          <Input
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              setIsAllSelected(event.target.checked);
              setSelectedRows([]);
            }}
          />
        </Label>
      ),
      selector: (row) => (
        <Label onClick={(e) => e.stopPropagation()} className="table-check-box">
          <Input
            type="checkbox"
            disabled={isAllSelected}
            checked={isAllSelected || Boolean(selectedRows?.find((item) => item === row?.id))}
            onChange={(event) => {
              handleSelectRow(row);
            }}
          />
        </Label>
      ),
      width: "80px",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.title")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={getColumnDetail("title_gen__title_en__icontains", "title_gen__title_pt__icontains")}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
            className="column-search-input"
            payload={customersPayload}
            setPayload={setCustomersPayload}
          />
        </div>
      ),
      selector: (row) =>
        row?.title_gen?.id ? getColumnDetail(row?.title_gen?.title_en, row?.title_gen?.title_pt) : "-",
      width: "150px",
      wrap: true,
    },

    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.name")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="search_customer__icontains"
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
            className="column-search-input"
            payload={customersPayload}
            setPayload={setCustomersPayload}
          />
        </div>
      ),
      selector: (row) => getOnlyCustomerName(row),
      //   width: "15%",
      width: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.email")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="email__icontains"
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
            className="column-search-input"
            payload={customersPayload}
            setPayload={setCustomersPayload}
          />
        </div>
      ),
      selector: (row) => row?.email,
      //   width: "15%",
      width: "180px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={`${t("common.address")}`}
            sortable
            sortableKey="customers_address__address"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <Select
            placeholder={t("common.select")}
            className="custom-select"
            isMulti={false}
            components={{ Option }}
            isClearable
            options={[SELECT_ALL_OPTION, ...ADDRESS_OPTION]}
            value={selectedAddress}
            onChange={(selectedItem) => {
              if (selectedItem === null) {
                setSelectedAddress("");
              }
              setSelectedAddress(selectedItem);
              handleAddressFilter(selectedItem, "customers_address__country");
            }}
          />
        </div>
      ),
      selector: (row) =>
        getCustomerAddress(row?.addresses, row?.customer_type === "company" ? "company" : "client") || "-",
      //   width: "15%",
      width: "200px",
      wrap: true,
    },
  ];

  return (
    <Modal centered isOpen={isModalOpen} toggle={onCancel} fade={false} size="xl" className="import-precontract-modal">
      <ModalHeader>
        <div className="d-flex w-100 flex-row justify-content-between align-items-center">
          <span>{`${t("common.select")} ${t("common.and")} ${t("common.notifySellers")}`}</span>

          {(isAllSelected && totalSellers > 0) || selectedRows.length ? (
            <>
              <Button
                color="info"
                onClick={() => {
                  setSelectedRows([]);
                  setIsAllSelected(false);
                }}
                className="d-flex justify-content-center align-items-center"
              >
                {`${t("common.deselect")}`}
                <Badge color="dark" className="ms-1">
                  {selectedRows.length || totalSellers}
                </Badge>
              </Button>
            </>
          ) : null}
        </div>
      </ModalHeader>

      <ModalBody>
        <Card className="subcard-table-container table-light">
          {tableLoader ? <Loader /> : null}
          <DataTable
            className="data-table"
            persistTableHead
            fixedHeader
            columns={columns}
            data={[...sellers]}
            pagination
            paginationServer
            paginationDefaultPage={customersPayload?.page}
            paginationPerPage={customersPayload?.limit}
            paginationTotalRows={totalSellers}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: t("common.rowsPerPage"),
              rangeSeparatorText: t("common.rangeSeparator"),
            }}
            pointerOnHover
            onRowClicked={(row) => {
              handleSelectRow(row);
            }}
          />
        </Card>
      </ModalBody>
      <ModalFooter>
        <CustomButton btnTitle={t("common.cancel")} disabled={loader} color="dark" onClick={onCancel} />
        <CustomButton
          onClick={() => {
            onSend({
              seller_ids: selectedRows,
              filter: customersPayload?.filter,
            });
          }}
          isLoading={loader}
          btnTitle={t("common.send")}
          disabled={(isAllSelected && totalSellers === 0) || (selectedRows?.length === 0 && !isAllSelected)}
          color="primary"
        />
      </ModalFooter>
    </Modal>
  );
};

export default withTranslation()(ChooseSellersModal);
