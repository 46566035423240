import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Badge, Button, Card, CardHeader, Input } from "reactstrap";
import CustomAddButton from "../../components/common/CustomAddButton";
import CustomMultiSelect from "../../components/common/CustomMultiSelect";
import CustomSearchInput from "../../components/common/CustomSearchInput";
import CustomTooltipWrapper from "../../components/common/CustomTooltipWrapper";
import DeleteModal from "../../components/common/DeleteModal";
import Loader from "../../components/common/Loader";
import NoRecords from "../../components/common/NoRecords";
import { ADMIN, COMUNICATIONS } from "../../helpers/constants";

import debounce from "lodash.debounce";
import { toast } from "react-toastify";
import CustomButton from "../../components/common/CustomButton";
import CustomDropdownButton from "../../components/common/CustomDropdownButton";
import DateRangeInput from "../../components/common/DateRangeInput";
import API from "../../helpers/api/api_interceptor";
import { DEBOUNCE_TIME, DEFAULT_ROW_PER_PAGE } from "../../helpers/constants";
import { DOCUMENT_TAG_TYPES, SELECT_ALL_OPTION, YES_NO_OPTIONS } from "../../helpers/constants/selectOptions";
import {
  checkPermissionByRoles,
  checkSelectAllSelected,
  exportToexcel,
  formatAmount,
  generateExcelDataForInvoice,
  getDateInDDMMMYYYYFormat,
  getDateInDDMMYYYYFormat,
  getDateInYYYYMMDDFormat,
  getLabelValueArray,
  getTypeFromValue,
  getValuesFromArray,
  openFileURL,
} from "../../helpers/utils";
import { getDocumentTypes } from "../../store/document-types/actions";
import { deleteInvoice, getInvoices } from "../../store/invoicing/actions";
import CustomPagination from "../../components/common/CustomPagination";

const initialInvoicePayload = {
  page: 1,
  limit: DEFAULT_ROW_PER_PAGE,
  filter: {},
  search: "",
};

const Invoicing = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    data: invoicesData,
    total: totalInvoices,
    all_ids: allDocumentIds,
  } = useSelector((state) => state?.Invoicing);

  const DOCUMENT_TYPE_OPTIONS = getLabelValueArray(
    useSelector((state) => state?.DocumentTypes?.data),
    "id",
    "description"
  );
  const [documentTypesPayload, setDocumentTypesPayload] = useState({
    page: 1,
    sort: "description",
    filter: {},
  });
  const [invoicePayload, setInvoicePayload] = useState(initialInvoicePayload);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedDocumentTypeOption, setSelectedDocumentTypeOption] = useState([]);
  const [selectedSentOptions, setSelectedSentOptions] = useState([]);
  const [selectedPaidOptions, setSelectedPaidOptions] = useState([]);
  const [selectedStatusTags, setSelectedStatusTags] = useState([]);
  const [exportPDFLoader, setExportPDFLoader] = useState(false);
  const [exportExcelLoader, setExportExcelLoader] = useState(false);
  const [viewPTDocumentLoader, setViewPTDocumentLoader] = useState(false);
  const [viewENDocumentLoader, setViewENDocumentLoader] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [tableLoader, setTableLoader] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [documentTypeSearchLoader, setDocumentTypeSearchLoader] = useState(false);
  const [clearSearch, setClearSearch] = useState();
  const [isForceDelete, setForceDelete] = useState(false);
  const [exclude, setExclude] = useState([]);

  //   navigate on invoice from
  const navigateOnInvoiceForm = (id) => {
    if (id) {
      history.push(`/invoicing/edit/${id}`);
    } else {
      history.push("/invoicing/new");
    }
  };

  const handleClearFilters = () => {
    // Clear all filter-related states
    setInvoicePayload(initialInvoicePayload);
    setSelectedDocumentTypeOption([]);
    setSelectedSentOptions([]);
    setSelectedStatusTags([]);
    setSelectedDateRange(null);
    setIsAllSelected(false);
    setSelectedRows([]);
    setClearSearch(true);
    setSelectedPaidOptions([]);
  };

  // to handle the pdf exporting
  const handleExportPDF = (isEnglish) => {
    const payload = {
      is_english: isEnglish,
      ids: selectedRows,
    };
    // setExportPDFLoader(true);
    // dispatch(exportInvoices(payload)).then((res) => {
    //   if (res) {
    //     openFileURL(res, "download");
    //   }
    //   setExportPDFLoader(false);
    // });
  };

  // handle row selection
  const handleRowSelection = (checked, rowId) => {
    if (checked) {
      setSelectedRows((prevState) => [...prevState, rowId]);
    } else {
      setSelectedRows((prevState) => prevState.filter((item) => item !== rowId));
      setExclude((prevExclude) => [...prevExclude, rowId]);
    }
  };

  // handle export to excel
  const handleExportExcel = () => {
    setExportExcelLoader(true);
    let filter = {};
    let excludePayload = {};
    if (exclude?.length) {
      excludePayload.id__in = exclude;
    } else if (!isAllSelected && selectedRows?.length) {
      filter.id__in = selectedRows;
    }
    API.get(`/billings/documents`, {
      params: {
        search: selectedRows?.length ? "" : invoicePayload?.search,
        filter: selectedRows?.length ? filter : { ...filter, ...invoicePayload?.filter },
        exclude: excludePayload,
      },
    })
      .then((res) => {
        if (res && res.status && res.status === 200) {
          exportToexcel(
            generateExcelDataForInvoice(res?.data?.data?.results),
            `${props.t("common.invoices")}-${getDateInDDMMYYYYFormat(new Date())}`,
            [20, 15, 20, 20, 10, 10, 10, 10]
          );
        } else {
          // return false;
        }
        setExportExcelLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setExportExcelLoader(false);
      });
  };

  // delete action handlers
  const handleDeleteModalOpen = (invoiceData) => {
    setSelectedInvoice(invoiceData);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setSelectedInvoice(null);
    setIsDeleteModalOpen(false);
    setForceDelete(false);
  };
  const handleDeleteInvoice = () => {
    if (selectedInvoice && selectedInvoice?.id) {
      setDeleteLoader(true);
      dispatch(deleteInvoice(selectedInvoice?.id, { force_delete: isForceDelete }))
        .then((res) => {
          if (res?.data?.status === 200 && !res?.data?.force_delete) {
            setForceDelete(true);
          } else if (res?.data?.status === 204) {
            toast.success(res?.data?.detail);
            handleDeleteModalClose();
            handleGetInvoices(invoicePayload);
          }
          setDeleteLoader(false);
        })
        .catch((error) => {
          setDeleteLoader(false);
          handleDeleteModalClose();
          console.log(error);
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setInvoicePayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };
  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setInvoicePayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle search document types
  const handleDocumentTypeSearch = (value) => {
    setDocumentTypesPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, description__icontains: value },
    }));
  };
  const documentTypeSearchDebounce = useMemo(() => {
    return debounce(handleDocumentTypeSearch, DEBOUNCE_TIME);
  }, []);

  // to cancel all the debounce
  useEffect(() => {
    return () => {
      documentTypeSearchDebounce.cancel();
    };
  }, [documentTypeSearchDebounce]);

  const handleDropdownFilter = (values, filterKey, setOptionTypes) => {
    if (checkSelectAllSelected(values)) {
      setOptionTypes(SELECT_ALL_OPTION);
      setInvoicePayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload?.filter?.[filterKey];
        return { ...tempPayload, page: 1 };
      });
    } else {
      if (values.length > 0) {
        setInvoicePayload((prevState) => ({
          ...prevState,
          filter: {
            ...prevState.filter,
            [filterKey]: getValuesFromArray(values),
          },
          page: 1,
        }));
      } else {
        setInvoicePayload((prevState) => {
          const tempPayload = prevState;
          delete tempPayload?.filter?.[filterKey];
          return { ...tempPayload, page: 1 };
        });
      }
    }
  };

  // handle get invoices
  const handleGetInvoices = useCallback(
    (invoicePayload) => {
      setTableLoader(true);
      dispatch(getInvoices(invoicePayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  // to get the list of the invoices
  useEffect(() => {
    handleGetInvoices(invoicePayload);
  }, [invoicePayload, handleGetInvoices]);

  // to get the list of document types
  useEffect(() => {
    setDocumentTypeSearchLoader(true);
    dispatch(getDocumentTypes(documentTypesPayload)).then(() => {
      setDocumentTypeSearchLoader(false);
    });
  }, [documentTypesPayload, dispatch]);

  // handle date filter
  useEffect(() => {
    if (selectedDateRange) {
      setInvoicePayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState?.filter,
          date__range: [
            getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate)),
            getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate)),
          ],
        },
      }));
    } else {
      setInvoicePayload((prevState) => {
        delete prevState?.filter?.date__range;
        return { ...prevState };
      });
    }
  }, [selectedDateRange]);

  const handleSelectAll = (event) => {
    const hasFilterOrSearchApplied = !!Object.keys(invoicePayload?.filter)?.length || !!invoicePayload?.search?.length;
    const isAllSelected = !!event.target.checked;

    if (!isAllSelected) {
      if (hasFilterOrSearchApplied) {
        setSelectedRows((prevState) => [...prevState?.filter((item) => !allDocumentIds?.includes(item))]);
      } else {
        setSelectedRows([]);
      }
    } else {
      if (hasFilterOrSearchApplied) {
        setSelectedRows((prevState) => [...new Set([...prevState, ...allDocumentIds])]);
      } else {
        setSelectedRows(allDocumentIds);
      }
    }
    setIsAllSelected(isAllSelected);
    setExclude([]);
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4 d-flex flex-row justify-content-center">
          <Input
            disabled={!invoicesData?.length}
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              handleSelectAll(event);
            }}
          />
        </div>
      ),
      selector: (row) => (
        <Input
          disabled={!invoicesData?.length}
          type="checkbox"
          checked={Boolean(selectedRows.find((item) => item === row.id))}
          onChange={(e) => handleRowSelection(e.target.checked, row.id)}
        />
      ),
      width: "50px",
      center: true,
    },
    // {
    //   name: (
    //     <div className="font-weight-bold fs-14 table-column-container py-4">
    //       <div className="table-column-title mb-2">
    //         {props.t("common.documentNo")}
    //       </div>
    //       <CustomSearchInput
    //         columnWise={true}
    //         columnSearchKey="document_no__icontains"
    //         className="column-search-input"
    //         payload={invoicePayload}
    //         setPayload={setInvoicePayload}
    //       />
    //     </div>
    //   ),
    //   selector: (row) => (row?.document_no ? row?.document_no : "-"),
    //   width: "135px",
    //   wrap: true,
    // },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.phcDocumentNo")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="phc_document_no__icontains"
            className="column-search-input"
            payload={invoicePayload}
            setPayload={setInvoicePayload}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
          />
        </div>
      ),
      selector: (row) => (row?.phc_document_no ? row?.phc_document_no : "-"),
      width: "135px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.internalNo")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="document_no__icontains"
            className="column-search-input"
            payload={invoicePayload}
            setPayload={setInvoicePayload}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
          />
        </div>
      ),
      selector: (row) => (row?.document_no ? row?.document_no : "-"),
      width: "135px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.date")}</div>
          <DateRangeInput
            classes={"column-date-range"}
            inputValue={
              selectedDateRange
                ? `${getDateInDDMMMYYYYFormat(new Date(selectedDateRange?.startDate))} ${props.t(
                    "common.to"
                  )} ${getDateInDDMMMYYYYFormat(new Date(selectedDateRange?.endDate))}`
                : ""
            }
            rangeValue={selectedDateRange}
            onChange={(values) => {
              setSelectedDateRange(values);
            }}
            onClear={() => {
              setSelectedDateRange(null);
            }}
          />
        </div>
      ),
      selector: (row) => (row?.date ? getDateInDDMMMYYYYFormat(new Date(row?.date)) : "-"),
      width: "280px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.mainCustomer")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="search_name__icontains"
            className="column-search-input"
            payload={invoicePayload}
            setPayload={setInvoicePayload}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
          />
        </div>
      ),
      selector: (row) =>
        row?.customer?.customer_type === "company"
          ? row?.customer?.company_name || "-"
          : row?.customer?.id
          ? row?.customer?.name + " " + row?.customer?.surname
          : "-",
      width: "225px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.billingCustomer")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="search_name__icontains"
            className="column-search-input"
            payload={invoicePayload}
            setPayload={setInvoicePayload}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
          />
        </div>
      ),
      selector: (row) =>
        row?.billing_customer?.customer_type === "company"
          ? row?.billing_customer?.company_name || "-"
          : row?.billing_customer?.id
          ? row?.billing_customer?.name + " " + row?.billing_customer?.surname
          : "-",
      width: "225px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.documentType")}</div>
          <CustomMultiSelect
            placeholder={props.t("common.select")}
            isLoading={documentTypeSearchLoader}
            options={[SELECT_ALL_OPTION, ...DOCUMENT_TYPE_OPTIONS]}
            selectedOptions={selectedDocumentTypeOption}
            handleOnSelection={(values) => {
              setSelectedDocumentTypeOption(values);
              handleDropdownFilter(values, "document_type__in", setSelectedDocumentTypeOption);
            }}
          />
        </div>
      ),
      selector: (row) => (row?.document_type?.id ? row?.document_type?.description : "-"),
      width: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.total")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="total__icontains"
            className="column-search-input"
            payload={invoicePayload}
            setPayload={setInvoicePayload}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
          />
        </div>
      ),
      selector: (row) => formatAmount(row?.total || 0) + " €",
      width: "135px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.totalToSettle")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="total_to_settle__icontains"
            className="column-search-input"
            payload={invoicePayload}
            setPayload={setInvoicePayload}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
          />
        </div>
      ),
      selector: (row) => (
        <span style={{ color: +row?.total_to_settle > 0 ? "red" : "" }}>
          {formatAmount(+row?.total_to_settle || 0) + " €"}
        </span>
      ),
      width: "135px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.paid")}</div>
          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[SELECT_ALL_OPTION, ...YES_NO_OPTIONS]}
            selectedOptions={selectedPaidOptions}
            handleOnSelection={(values) => {
              setSelectedPaidOptions(values);
              handleDropdownFilter(values, "is_settled__in", setSelectedSentOptions);
            }}
          />
        </div>
      ),
      selector: (row) => (row?.is_settled ? props.t("common.yes") : props.t("common.no")),
      width: "165px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.invoiceSent")}</div>
          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[SELECT_ALL_OPTION, ...YES_NO_OPTIONS]}
            selectedOptions={selectedSentOptions}
            handleOnSelection={(values) => {
              setSelectedSentOptions(values);
              handleDropdownFilter(values, "is_sent__in", setSelectedSentOptions);
            }}
          />
        </div>
      ),
      selector: (row) => (row?.is_sent ? props.t("common.yes") : props.t("common.no")),
      width: "165px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.TAGS")}</div>
          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[
              {
                value: "*",
                text: props.t("common.selectAll"),
              },
              ...DOCUMENT_TAG_TYPES,
            ]}
            getOptionLabel={(e) => (
              <div className="d-flex align-items-center">
                <i class={`fs-18 ${e.iconClasses}`} />
                <span className="ms-1">{props.t(e.text)}</span>
              </div>
            )}
            selectedOptions={selectedStatusTags}
            handleOnSelection={(values) => {
              setSelectedStatusTags(values);
              handleDropdownFilter(values, "status", setSelectedStatusTags);
            }}
          />
        </div>
      ),
      selector: (row) => {
        let tag;
        let tooltip;
        if (row?.is_cancel) {
          tag = "is_cancel";
          tooltip = "invoiceTagTooltip.cancelled";
        } else if (row?.is_settled) {
          tag = "is_settled";
          tooltip = "invoiceTagTooltip.settled";
        } else if (row?.is_issued) {
          tag = "is_issued";
          tooltip = "invoiceTagTooltip.issued";
        } else if (row?.is_not_reconciled) {
          tag = "is_not_reconciled";
          tooltip = "invoiceTagTooltip.notSettled";
        }
        return (
          <>
            <CustomTooltipWrapper target={`tooltip-${row?.id}`} tooltipBody={props.t(tooltip)} />
            <div id={`tooltip-${row?.id}`}>
              <i class={`fs-24 ${getTypeFromValue(DOCUMENT_TAG_TYPES, tag)?.iconClasses} text-decoration-none-cell`} />
            </div>
          </>
        );
      },
      width: "200px",
      wrap: true,
      center: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.viewDocument")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="d-flex flex-row gap-2 text-decoration-none-cell">
          <>
            {row?.phc_file_pt !== null ? (
              <CustomButton
                isLoading={selectedInvoice === row?.id && viewPTDocumentLoader}
                type="button"
                btnTitle={props.t("common.pt")}
                onClick={() => {
                  if (row?.phc_file_pt) openFileURL(row?.phc_file_pt, "preview");
                }}
              />
            ) : (
              " "
            )}
            {row?.phc_file_en !== null ? (
              <CustomButton
                isLoading={selectedInvoice === row?.id && viewENDocumentLoader}
                type="button"
                btnTitle={props.t("common.en")}
                onClick={() => {
                  if (row?.phc_file_en) openFileURL(row?.phc_file_en, "preview");
                }}
              />
            ) : (
              "  "
            )}
          </>
        </div>
      ),
      width: "180px",
      center: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.action")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          {/* {checkPermissionByRoles([ADMIN]) && !row?.is_cancel ? ( */}
          {!(row?.is_cancel || row?.credit_note_issued) ? (
            <button
              type="button"
              className={`btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none ${
                checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "" : "disabled"
              }`}
              onClick={() => {
                handleDeleteModalOpen(row);
              }}
              disabled={!checkPermissionByRoles([ADMIN, COMUNICATIONS])}
              style={{
                outline: "none",
                border: "none",
                opacity: checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "1" : "0.5",
                cursor: checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "pointer" : "not-allowed",
              }}
            >
              <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
              <i
                className={`ri-delete-bin-fill fs-18 ${
                  checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "text-danger" : "text-grey"
                }`}
                id={`tooltip-${row?.id}-delete`}
                style={{
                  color: checkPermissionByRoles([ADMIN, COMUNICATIONS]) ? "inherit" : "grey",
                  border: "none",
                }}
              />
            </button>
          ) : null}
        </div>
      ),
      width: "80px",
      center: true,
    },
  ];

  document.title = `Veritas | ${props.t("sidebarMenu.invoicing")}`;

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header Invoicing-table-header">
          <CustomSearchInput
            className="custom-search-input"
            payload={invoicePayload}
            setPayload={setInvoicePayload}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
            disabled={tableLoader}
          />
          <div className="contain-header-right d-flex flex-row gap-2">
            {(isAllSelected && totalInvoices > 0) || selectedRows.length ? (
              <Button
                color="info"
                onClick={() => {
                  setSelectedRows([]);
                  setIsAllSelected(false);
                }}
              >
                {props.t("common.deselect")}
                <Badge color="dark" className="ms-1">
                  {selectedRows.length || totalInvoices}
                </Badge>
              </Button>
            ) : null}
            <Button color="light" onClick={handleClearFilters} disabled={tableLoader}>
              {props.t("common.clearFilters")}
            </Button>
            <CustomAddButton
              color={"success"}
              btnTitle={props.t("common.addTitle", {
                module: props.t("common.document"),
              })}
              onClick={() => navigateOnInvoiceForm()}
            />
            <CustomAddButton
              btnTitle={props.t("common.addTitle", {
                module: `${props.t("common.customized")} ${props.t("common.invoice")}`,
              })}
              onClick={() =>
                history.push({
                  pathname: "/invoicing/new",
                  state: { type: "customized" },
                })
              }
            />
            {/* <CustomButton
              type="button"
              btnTitle={props.t("common.share")}
              onClick={() => handleOpenMailApp()}
            /> */}
            <CustomDropdownButton
              disabled={tableLoader || totalInvoices <= 0}
              isLoading={exportPDFLoader || exportExcelLoader}
              btnTitle={props.t("common.export")}
              options={[
                {
                  label: props.t("common.pdf", { lang: "" }),
                  onClick: () => {
                    handleExportPDF(true);
                  },
                },
                {
                  label: props.t("common.excel"),
                  onClick: () => {
                    handleExportExcel();
                  },
                },
              ]}
            />
          </div>
        </div>
        <Card className="container-body">
          {tableLoader ? <Loader /> : null}
          <CardHeader className="table-title">{props.t("sidebarMenu.invoicing")}</CardHeader>
          <DataTable
            pointerOnHover
            fixedHeader
            className="data-table"
            persistTableHead
            onRowClicked={(row) => {
              navigateOnInvoiceForm(row?.id);
            }}
            columns={columns}
            data={[...invoicesData]}
            pagination
            paginationServer
            paginationDefaultPage={invoicePayload?.page}
            paginationPerPage={invoicePayload?.limit}
            paginationTotalRows={totalInvoices}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponent={CustomPagination}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
            conditionalRowStyles={[
              {
                when: (row) => row?.is_cancel,
                classNames: ["canceled-table-cell"],
              },
              {
                when: (row) => row?.credit_note_issued,
                classNames: ["credit-note-issued-cell"],
              },
            ]}
          />
        </Card>
      </div>

      {/* delete modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeleteInvoice}
        title={`${props.t("common.delete")} ${props.t("common.document")}`}
        body={
          isForceDelete
            ? props.t("information.documentNotReconciled")
            : props.t("confirmation.deleteMessage", {
                module: props.t("common.document"),
              })
        }
        actionBtnTitle={isForceDelete ? props.t("common.confirm") : props.t("common.delete")}
        loader={deleteLoader}
      />
    </>
  );
};

export default withTranslation()(Invoicing);
