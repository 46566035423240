import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Badge, Button, Card, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CustomButton from "../../common/CustomButton";
import Loader from "../../common/Loader";
import DataTable from "react-data-table-component";
import NoRecords from "../../common/NoRecords";
import { useDispatch, useSelector } from "react-redux";
import CustomSearchInput from "../../common/CustomSearchInput";

import { checkSelectAllSelected, getOnlyCustomerName, getValuesFromArray, openFileURL } from "../../../helpers/utils";
import { DEFAULT_ROW_PER_PAGE, PROFORMA_INVOICE_DOCUMENT } from "../../../helpers/constants";
import { useParams } from "react-router-dom";
import { exportInvoice, getInvoices } from "../../../store/invoicing/actions";
import CustomMultiSelect from "../../common/CustomMultiSelect";
import { SELECT_ALL_OPTION, YES_NO_OPTIONS } from "../../../helpers/constants/selectOptions";

const INITIAL_PAYLOAD = {
  page: 1,
  limit: DEFAULT_ROW_PER_PAGE,
  filter: { document_type__description: PROFORMA_INVOICE_DOCUMENT, is_sent: false, is_cancel: false },
};

const ChooseProformaInvoicesModal = ({ isModalOpen, onSend, onCancel, loader, t }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { data: documents, total: totalDocuments } = useSelector((state) => state?.Invoicing);

  const [documentsPayload, setDocumentsPayload] = useState({
    ...INITIAL_PAYLOAD,
    filter: { ...INITIAL_PAYLOAD?.filter, document_lines__auction: id },
  });

  const [selectedSentOptions, setSelectedSentOptions] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [viewPTDocumentLoader, setViewPTDocumentLoader] = useState(false);
  const [viewENDocumentLoader, setViewENDocumentLoader] = useState(false);

  const [tableLoader, setTableLoader] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  //   handle view doucment
  const handleViewDocument = (invoice, isEnglish) => {
    const payload = { isEnglish };
    if (isEnglish) {
      setViewENDocumentLoader(true);
    } else {
      setViewPTDocumentLoader(true);
    }
    setSelectedInvoice(invoice?.id);
    dispatch(exportInvoice(invoice?.id, payload)).then((res) => {
      if (res) {
        openFileURL(res, "download");
        // handleOpenMailApp(invoice?.customer?.email);
        handleGetDocuments(documentsPayload);
      }
      setSelectedInvoice(null);
      setViewENDocumentLoader(false);
      setViewPTDocumentLoader(false);
    });
  };

  // handle page change
  const handlePageChange = (value) => {
    setDocumentsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setDocumentsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle get documents
  const handleGetDocuments = useCallback(
    (documentsPayload) => {
      setTableLoader(true);
      dispatch(getInvoices(documentsPayload))
        .then((res) => {
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log(error);
        });
    },
    [dispatch]
  );

  // to get the list of the documents
  useEffect(() => {
    if (isModalOpen) {
      setTableLoader(true);
      handleGetDocuments(documentsPayload);
    }
  }, [isModalOpen, documentsPayload, handleGetDocuments]);

  // to reset the form
  useEffect(() => {
    if (!isModalOpen && id) {
      setDocumentsPayload({
        ...INITIAL_PAYLOAD,
        filter: { ...INITIAL_PAYLOAD?.filter, document_lines__auction: id },
      });
      setIsAllSelected(false);
      setSelectedRows([]);
      setSelectedSentOptions([]);
    }
  }, [isModalOpen, id]);

  // to fiter documents by auction id
  useEffect(() => {
    if (id) {
      setDocumentsPayload((prevState) => ({
        ...prevState,
        filter: {
          ...prevState?.filter,
          //   auction: id,
        },
      }));
    }
  }, [id]);

  const handleDropdownFilter = (values, filterKey, setOptionTypes) => {
    if (checkSelectAllSelected(values)) {
      setOptionTypes(SELECT_ALL_OPTION);
      setDocumentsPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload?.filter?.[filterKey];
        return { ...tempPayload, page: 1 };
      });
    } else {
      if (values.length > 0) {
        setDocumentsPayload((prevState) => ({
          ...prevState,
          filter: {
            ...prevState.filter,
            [filterKey]: getValuesFromArray(values),
          },
          page: 1,
        }));
      } else {
        setDocumentsPayload((prevState) => {
          const tempPayload = prevState;
          delete tempPayload?.filter?.[filterKey];
          return { ...tempPayload, page: 1 };
        });
      }
    }
  };

  const handleModalClose = () => {
    if (!loader) onCancel();
  };

  const handleSelectRow = (row) => {
    if (!isAllSelected) {
      if (selectedRows?.find((item) => item === row?.id)) {
        setSelectedRows((prevState) => [...prevState?.filter((item) => item !== row?.id)]);
      } else {
        setSelectedRows((prevState) => [...prevState, row?.id]);
      }
    }
  };

  const columns = [
    {
      name: (
        <Label
          onClick={(e) => e.stopPropagation()}
          className="py-4 d-flex flex-row justify-content-center table-check-box"
        >
          <Input
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              setIsAllSelected(event.target.checked);
              setSelectedRows([]);
            }}
          />
        </Label>
      ),
      selector: (row) => (
        <Label onClick={(e) => e.stopPropagation()} className="table-check-box">
          <Input
            type="checkbox"
            disabled={isAllSelected}
            checked={isAllSelected || Boolean(selectedRows?.find((item) => item === row?.id))}
            onChange={(event) => {
              handleSelectRow(row);
            }}
          />
        </Label>
      ),
      width: "80px",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.documentNo")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="document_no__icontains"
            className="column-search-input"
            payload={documentsPayload}
            setPayload={setDocumentsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.document_no ? row?.document_no : "-"),
      width: "150px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.customer")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="search_name__icontains"
            className="column-search-input"
            payload={documentsPayload}
            setPayload={setDocumentsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.customer?.id ? getOnlyCustomerName(row?.customer) : "-"),
      width: "160px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.total")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="total__icontains"
            className="column-search-input"
            payload={documentsPayload}
            setPayload={setDocumentsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.total || 0) + " €",
      width: "150px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.totalToSettle")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="total_to_settle__icontains"
            className="column-search-input"
            payload={documentsPayload}
            setPayload={setDocumentsPayload}
          />
        </div>
      ),
      selector: (row) => (row?.total_to_settle || 0) + " €",
      width: "150px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.sent")}</div>
          <CustomMultiSelect
            options={[SELECT_ALL_OPTION, ...YES_NO_OPTIONS]}
            selectedOptions={selectedSentOptions}
            handleOnSelection={(values) => {
              setSelectedSentOptions(values);
              handleDropdownFilter(values, "is_sent__in", setSelectedSentOptions);
            }}
          />
        </div>
      ),
      selector: (row) => (row?.is_sent ? t("common.yes") : t("common.no")),
      width: "160px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{t("common.viewDocument")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="d-flex flex-row gap-2">
          <>
            {row?.phc_file_pt !== null ? (
              <CustomButton
                isLoading={selectedInvoice === row?.id && viewPTDocumentLoader}
                type="button"
                btnTitle={t("common.pt")}
                onClick={() => {
                  if (row?.phc_file_pt) openFileURL(row?.phc_file_pt, "preview");
                }}
              />
            ) : (
              " "
            )}
            {row?.phc_file_en !== null ? (
              <CustomButton
                isLoading={selectedInvoice === row?.id && viewENDocumentLoader}
                type="button"
                btnTitle={t("common.en")}
                onClick={() => {
                  if (row?.phc_file_en) openFileURL(row?.phc_file_en, "preview");
                }}
              />
            ) : (
              "  "
            )}
          </>
          {/* <CustomButton
            isLoading={selectedInvoice === row?.id && viewPTDocumentLoader}
            type="button"
            btnTitle={t("common.pt")}
            onClick={() => {
              handleViewDocument(row, false);
            }}
          />
          <CustomButton
            isLoading={selectedInvoice === row?.id && viewENDocumentLoader}
            type="button"
            btnTitle={t("common.en")}
            onClick={() => {
              handleViewDocument(row, true);
            }}
          /> */}
        </div>
      ),
      width: "190px",
      center: true,
    },
  ];

  return (
    <Modal
      centered
      isOpen={isModalOpen}
      toggle={handleModalClose}
      fade={false}
      size="xl"
      className="import-precontract-modal"
    >
      <ModalHeader>
        <div className="d-flex w-100 flex-row justify-content-between align-items-center">
          <span>{`${t("common.select")} ${t("common.and")} ${t("common.sendProformaInvoices")}`}</span>

          {(isAllSelected && totalDocuments > 0) || selectedRows.length ? (
            <>
              <Button
                color="info"
                onClick={() => {
                  setSelectedRows([]);
                  setIsAllSelected(false);
                }}
                className="d-flex justify-content-center align-items-center"
              >
                {`${t("common.deselect")}`}
                <Badge color="dark" className="ms-1">
                  {selectedRows.length || totalDocuments}
                </Badge>
              </Button>
            </>
          ) : null}
        </div>
      </ModalHeader>

      <ModalBody>
        <Card className="subcard-table-container table-light">
          {tableLoader ? <Loader /> : null}
          <DataTable
            className="data-table"
            persistTableHead
            fixedHeader
            columns={columns}
            data={[...documents]}
            pagination
            paginationServer
            paginationDefaultPage={documentsPayload?.page}
            paginationPerPage={documentsPayload?.limit}
            paginationTotalRows={totalDocuments}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: t("common.rowsPerPage"),
              rangeSeparatorText: t("common.rangeSeparator"),
            }}
            pointerOnHover
            onRowClicked={(row) => {
              handleSelectRow(row);
            }}
          />
        </Card>
      </ModalBody>
      <ModalFooter>
        <CustomButton btnTitle={t("common.cancel")} disabled={loader} color="dark" onClick={onCancel} />
        <CustomButton
          onClick={() => {
            onSend({
              document_ids: selectedRows,
              filter: documentsPayload?.filter,
            });
          }}
          isLoading={loader}
          btnTitle={t("common.send")}
          disabled={(isAllSelected && totalDocuments === 0) || (selectedRows?.length === 0 && !isAllSelected)}
          color="primary"
        />
      </ModalFooter>
    </Modal>
  );
};

export default withTranslation()(ChooseProformaInvoicesModal);
