import * as React from "react";
import styled from "styled-components";

const SelectControl = styled.select`
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  text-align: center;
  outline: none;
  margin-left: 4px;
  margin-right: 4px;
  border: none;
`;

const SelectWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.54);

  svg {
    top: 0;
    right: 0;
    color: inherit;
    position: absolute;
    fill: currentColor;
    display: inline-block;
    user-select: none;
    pointer-events: none;
  }
`;

const Select = ({ defaultValue, onChange, ...rest }) => (
  <SelectWrapper>
    <SelectControl onChange={onChange} defaultValue={defaultValue} {...rest} />
    {/* <DropDownIcon /> */}
  </SelectWrapper>
);

export default Select;
